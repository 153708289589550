.sidebar .nav-dropdown-items .nav-item {
    margin-left: 15px;
}

@media (min-width: 992px) {
    .sidebar-fixed .sidebar {
        width: 210px !important;
    }
}
.billCheckSpan{
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    display: inline-block;
}
.sidebar .nav-link {
    padding: 0.75rem 0.5rem !important;
}

.sidebar .sidebar-nav {
    width: 210px !important;
}

.sidebar .nav {
    width: 210px !important;
}

.sidebar {
    background: #173c5f !important;
}

.sidebar .nav-link {
    color: #fff074 !important;
}

.sidebar .nav-title {
    padding: 0.75rem 1rem;
    font-size: 94% !important;
    font-weight: 700;
    color: #63c2de !important;
    text-transform: uppercase;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 64px;
    height: 64px;
}

.lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #fff;
    margin: -3px 0 0 -3px;
}

.lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
    top: 50px;
    left: 50px;
}

.lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
    top: 54px;
    left: 45px;
}

.lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
    top: 57px;
    left: 39px;
}

.lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
    top: 58px;
    left: 32px;
}

.lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
    top: 57px;
    left: 25px;
}

.lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
    top: 54px;
    left: 19px;
}

.lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
    top: 50px;
    left: 14px;
}

.lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
    top: 45px;
    left: 10px;
}

@keyframes lds-roller {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.disabled {
    cursor: not-allowed;
    pointer-events: none;

    /*Button disabled - CSS color class*/
    color: #c0c0c0 !important;
    background-color: #ffffff !important;
}

.page-link:hover {
    z-index: 2;
    color: #fff !important;
    background-color: #20a8d8 !important;
    border-color: #20a8d8;
}

.hide {
    display: none !important;
}

.customerreviews {
    padding-top: 50px;
    display: table;
    margin: 0 auto;
    width: 50%;
    border: 1px solid cadetblue;
    padding: 20px;
    margin-top: 50px;
    border-radius: 10px;
    background: aliceblue;
    -webkit-box-shadow: 0px 0px 30px 3px rgba(22, 61, 18, 0.72);
    -moz-box-shadow: 0px 0px 30px 3px rgba(22, 61, 18, 0.72);
    box-shadow: 0px 0px 30px 3px rgba(22, 61, 18, 0.72);
}

.right {
    float: right;
}

.dv-star-rating {
    font-size: 40px;
}

.bold {
    font-weight: 700;
    color: chocolate;
}


.bold-x {
    font-weight: 700;
    color: chocolate;
    animation: blinker 1s linear infinite;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.bold-y {
    font-weight: 700;
    color: indigo !important;
    animation: yyy 1s cubic-bezier(0.755, 0.05, 0.855, 0.06) infinite;
}

@keyframes yyy {
    50% {
        opacity: 0;
    }
}



.center {
    display: table;
    margin: 0 auto;
    width: 100%;
    text-align: center;
    margin-bottom: 30px !important;
}

img.img-avatar-user:hover {
    max-width: 100%;
    height: auto;
    border-radius: 50em;
    -moz-transform: scale(1.1);
    -webkit-transform: scale(1.1);
    transform: scale(1.4);
    margin: 12px 0;
    cursor: pointer;
}

.rating {
    margin: 15px 0;
}

/* img.img-avatar-user {
    max-width: 100%;
    height: auto;
    border-radius: 50em;

} */
@media only screen and (max-width: 600px) {
    .customerreviews {
        width: 100%;
        margin-top: 0;
        border-radius: 0;
        border: none;
        height: 100%;
        min-height: 100%;
    }
}

#profile-page h1 {
    font-size: 28px;
    color: #333;
    margin-bottom: 20px;
}

#profile-page p {
    color: #1a1a1a;
    margin: 0 0 10px;
}

.required {
    color: red;
}

p {
    color: #1a1a1a;
    margin: 0 0 10px;
}

label.label-bold {
    margin-top: 5px;
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
    font-size: 14px;
    font-family: "Helvetica Neue", arial, Georgia, Serif;
    line-height: 1.4;
    color: #1a1a1a;
}

.profile-pic-background {
    display: table;
    margin: 10px auto;
    -ms-filter: "alpha(opacity=30)";
    filter: alpha(opacity=30);
}

.app-header .nav-item .nav-link {
    color: lightslategray;
}

.marquee {
    width: 20%;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    color: lightslategray;
    font-size: larger;
    margin-left: 40px;
}

.app-header .nav-item .nav-link:hover {
    font-size: larger;
    color: brown;
    transform-style: preserve-3d;
    transition-delay: 5ms;
    -webkit-transition: background-color 3s ease-out;
    -moz-transition: background-color 3s ease-out;
    -o-transition: background-color 3s ease-out;
    transition: background-color 3s ease-out;
}

.vkshare {
    background: url("/../public/image/unnamed.jpg")
}

body {
    font-family: "Helvetica Neue", arial, Georgia, Serif;
    font-size: 14px;
    line-height: 1.4;
    width: 100%;
    color: #1a1a1a;
    background-color: #fff;
    position: initial;
    overflow-x: hidden !important;
    transition: all 0.3s ease;
    padding: 0;
    margin: 0;
    background: url("/../public/image/unnamed.jpg")
        /* letter-spacing: 0.014em; */
}

main.main {
    /* background: url("/../public/image/unnamed.jpg") */
    background-image: url(http://yesofcorsa.com/wp-content/uploads/2017/03/4K-Clouds-Desktop-Wallpaper-HD.jpg) !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    box-sizing: border-box;
    width: 100%;
}

.sidebar {
    /* background-image: url(http://giaoduc.net.vn/Uploaded/truyenthong/2012_02_06/02835_beforethestorm_1920x1200.jpg) !important; */
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    position: relative;
    box-sizing: border-box;
    width: 100%;
}

footer.app-footer {
    background-color: khaki;
}

.app.flex-row.align-items-center {
    background-image: url('/image/uuu.jpg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    position: relative;
    /* box-sizing: border-box; */
    width: 100%;
}

.customerreviews {
    /* background-image: url('/image/uuu.jpg') !important;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important; */
    background-color: #fff074;
    position: relative;
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-button {
    width: 6px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background: rgb(100, 4, 255);
    border: thin solid gray;
    border-radius: 12px;
}

::-webkit-scrollbar-track {
    background: #eee;
    border: thin solid #d3d3d3;
    box-shadow: 0 0 3px #dfdfdf inset;
    border-radius: 12px;
}

/* //upload file css */
/*****************************************
  upload button styles
******************************************/
.file-upload {
    position: relative;
    display: inline-block;
}

.file-upload__label {
    /* display: block; */
    /* padding: 6px 5px; */
    color: #fff;
    font-size: 0.875rem;
    background-color: #20a8d8;
    border-color: #20a8d8;
    margin: 0 5px;
    font-weight: 400;
    white-space: nowrap;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    transition: background 0.3s;
}

.file-upload__label:hover {
    cursor: pointer;
    background: #008bb7;
}

.file-upload__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 1;
    width: 0;
    height: 100%;
    opacity: 0;
}

.loader-container{
    min-height: 100%;
    min-width: 100%;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0,6);
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.loader {
    border: 16px solid #f3f3f3; /* Light grey */
    border-top: 16px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }